import JpControllerBase from './JpControllerBase.js';

function NewsLetterController($el, options) {
  const _self = this;

  _self._handleCallApiSuccess = _handleCallApiSuccess.bind(_self);
  _self._handleCallApiFail = _handleCallApiFail.bind(_self);

  JpControllerBase.call(_self, $el, options);

  _self.$submitButton = _self.$el.find('.js-newsletter-button');
  _self.$form = _self.$el.find('form');
  _self.formURL = _self.$form.attr('action');
  _self.$subscribeEmail = _self.$el.find('.js-subscribe-email');
  _self.$errorContainer = _self.$el.find('.js-error');
  _self.$apiSuccess = _self.$el.find('.js-api-success');
  _self.$apiError = _self.$el.find('.js-api-error');

  const method = 'POST';

  const customRules = {
    'subscribe-email': {
      required: true,
      validateEmail: true,
    },
  };

  const customMessages = {
    'subscribe-email': 'Please enter a valid email',
  };

  _self.$form.validate({
    // onfocusout: false,
    // onkeyup: false,
    rules: customRules,
    messages: customMessages,
    errorPlacement: (error, element) => {
      _self.$errorContainer.append(error);
      // setTimeout(() => {
      //   _self.$errorContainer.empty();
      // }, 5000);
    },
  });

  $.validator.addMethod('validateEmail',  (value, element) => {
    return /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
  });

  _self.$form.on('submit', (event) => {
    event.preventDefault();
    if (_self.$form.valid()) {
      const subscribeValue = _self.$subscribeEmail.val();

      const postData = {
        email: subscribeValue
      };

      window.APICaller.call(_self.formURL, method, postData, _self._handleCallApiSuccess, _self._handleCallApiFail);
    }
  });

  function _handleCallApiSuccess() {
    _self.$apiSuccess.show();
    setTimeout(() => {
      _self.$apiSuccess.hide();
    }, 3000);
  }

  function _handleCallApiFail() {
    _self.$apiError.show();
    setTimeout(() => {
      _self.$apiError.hide();
    }, 3000);
  }

}

NewsLetterController.prototype = Object.create(JpControllerBase.prototype);

NewsLetterController.prototype.render = function(state) {
  const _self = this;
};

export default NewsLetterController;
