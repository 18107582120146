import { TweenMax } from 'gsap/all';

$(function () {

  const $destinations = $('.js-destinations');
  if($destinations.length == 0) return;
  const $itemCards = $destinations.find('.js-item-card');
  const $cursor = $destinations.find('#cursor');
  $destinations.mousemove(function (e) {
    if (!(window.matchMedia('(max-width: 1023px)').matches || window.matchMedia('(pointer: coarse)').matches)) {
      parallaxIt(e, '.js-item-card', -316);
    }
  });

  function parallaxIt(e, target, movement) {
    const $this = $destinations;
    const relX = e.pageX - $this.offset().left;
    const relY = e.pageY - $this.offset().top;

    TweenMax.to(target, 1, {
      x: (relX - $this.width() / 2) / $this.width() * movement,
      y: (relY - $this.height() / 2) / $this.height() * 1
    });
  }

  $destinations.on('mousemove', function (event) {
    const x = event.pageX - 10;
    const y = event.pageY - 10;
    $cursor[0].style.left = x + 'px';
    $cursor[0].style.top = y + 'px';
  });

  $itemCards.on('mouseover', function () {
    $cursor.addClass('active');
  });

  $itemCards.on('mouseleave', function () {
    $cursor.removeClass('active');
  });


  let swiperOption = {
    allowTouchMove: true,
    slidesPerView: 'auto',
  };

  const $destinationsSwiper = $destinations.find('.js-swiper-container');


  handleSlickOnMobile();

  function handleSlickOnMobile() {
    if (window.matchMedia('(max-width: 1023px)').matches || window.matchMedia('(pointer: coarse)').matches) {
      if (!$destinationsSwiper.hasClass('swiper-initialized')) {
        $destinationsSwiper.toArray().forEach(item => {
          const swiper = new Swiper(item, swiperOption);
          $destinationsSwiper.addClass('custom-swiper');
        });
      }
    } else {
      if ($destinationsSwiper.hasClass('swiper-initialized')) {
        $destinationsSwiper.toArray().forEach(item => {
          const swiper = item.swiper;
          swiper.destroy(true, true);
          $destinationsSwiper.removeClass('custom-swiper');
        });
      }
    }
  }

  function checkPointerCoarse(){
    if (window.matchMedia('(max-width: 1023px)').matches || window.matchMedia('(pointer: coarse)').matches){
      $('.list-destinations').removeClass('not-coarse');
      $('#cursor').removeClass('not-coarse');
    } else {
      $('.list-destinations').addClass('not-coarse');
      $('#cursor').addClass('not-coarse');
    }
  }
  $(window).on('resize', () => {
    handleSlickOnMobile();
    checkPointerCoarse();
  });
  $( document ).ready(function() {
    checkPointerCoarse();
  });

});

