jQuery(document).ready(function () {
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  jQuery("[data-gtm-ga4]").on("click", function (e) {
    var dataGtm = jQuery(this).data('gtm-ga4');
    if (dataGtm) {
      let arrayGtm = [];
      if (Array.isArray(dataGtm)) {
        arrayGtm = dataGtm;
      } else {
        arrayGtm = [dataGtm];
      }

      arrayGtm.forEach( (gtm) => {
        gtag('event', gtm.eventName, gtm.eventData);
      });
    }
  });
  //DYnamic document render dom
  jQuery(document).on("click", "[data-gtm-ga4-document]" , function (e) {
    var dataGtm = jQuery(this).data('gtm-ga4-document');
    if (dataGtm && dataGtm.eventName) {
      gtag('event', dataGtm.eventName, dataGtm.eventData);
    }
  });

  jQuery(".gtm_ga4_custom_event_book_now:first").on("book_now_homepage", function (e, data) {
    var eventData = {};
    eventData.link_location = "booking mask";
    eventData.checkin_date = data.checkin;
    eventData.checkout_date = data.checkout;
    eventData.hotelName = data.hotelName;
    eventData.adults = data.adults;
    eventData.children = data.children;
    gtag('event', "click_to_booking_engine", eventData);
  });

  //Dynamic Event Date
  jQuery(".gtm_ga4_custom_event_dynamic_data").on("click", function (e) {
    var eventData = {};
    eventData.link_location = "offer booking mask";
    eventData.checkin_date = getParameterByName("checkin");
    eventData.checkout_date = getParameterByName("checkout");
    eventData.hotelName = getParameterByName("hotel");
    eventData.adults = getParameterByName("adults");
    eventData.children = getParameterByName("children");
    gtag('event', "click_to_booking_engine", eventData);
  });
  //Get event content cms
  jQuery("[data-gtm-ga4-parent]").on("click", function (e) {
    if (e.target.tagName.toLocaleLowerCase() == "a") {
      var dataGtm = jQuery(this).data('gtm-ga4-parent');
      if (dataGtm && dataGtm.eventName) {
        gtag('event', dataGtm.eventName, dataGtm.eventData);
      } else {
        var href = jQuery(e.target).attr('href');
        var eventName = "";
        var methodName = "";
        if (href.includes("tel:")) {
          methodName = "phone";
          eventName = "tel:";
        } else if (href.includes("mailto:")) {
          methodName = "email";
          eventName = "mailto:";
        }
        gtag('event', eventName, {
          "method": methodName,
        });
      }
    }
  });
});
