$(document).ready(() => {
  
  checkWidthHeightCard();
  positionContentDine();
  positionContentFeaturedOffers();
  positionContentCountryThingstodo();
  positionContentActivity();
  addJsHorizontalScroll();
  positionContentDineWs();
  positionContentVenus();
  positionContentVenusCountry();

  $(window).resize(function () {
    positionContentDine();
    checkWidthHeightCard();

    addJsHorizontalScroll();
    positionContentCountryThingstodo();
    positionContentActivity();
    positionContentFeaturedOffers();
    positionContentDineWs();
    positionContentVenus();
    positionContentVenusCountry();
  });

  function checkWidthHeightCard(){
    const $jsCheckwh = $('.js-check-wh-card');
    const $itemCard = $jsCheckwh.find('.item-card');
    if ($itemCard){
      let w_list = $('.destinations .list-card').width();
      $.each( $itemCard, function() {
        const $img = $(this).find('.item-card__img img');
        if ($img.prop('naturalHeight') > $img.prop('naturalWidth')){
          $(this).addClass('item-card--height');
        }
      });
      
      let count_item = $itemCard .length;
      count_item = count_item % 3 === 0 ? count_item / 3: count_item / 3 + 1;

      let w_3 = (w_list/3 - 84/2+ 84 + 22)+'px';
      let w_5_14 =  (5 * w_list/14 - 84/2+ 84 + 22)+'px';
      let w_5_13 =  (5 * w_list/13 - 84/2+ 84 + 22)+'px';
      let w_4_14 =  (4 * w_list/14 - 84/2+ 84 + 22)+'px';
      let w_4_13 =  (4 * w_list/13 - 84/2 + 84 + 22)+'px';

      for ( let i = 1; i <= count_item; i++){
        let count = 0;
        let n0 = 3*i, n2 = 3*i - 2, n1 = 3*i - 1;
        const item_card_n0 = '.destinations .item-card:nth-child('+n0+')';
        const item_card_n1 = '.destinations .item-card:nth-child('+n1+')';
        const item_card_n2 = '.destinations .item-card:nth-child('+n2+')';
        
        if ($(item_card_n0).hasClass('item-card--height')) count += 1;
        if ($(item_card_n1).hasClass('item-card--height')) count += 1;
        if ($(item_card_n2).hasClass('item-card--height')) count += 1;
        switch (count){
        case 0: case 3:
          $(item_card_n0).css('width', w_3);
          $(item_card_n1).css('width', w_3);
          $(item_card_n2).css('width', w_3);
          break;
        case 1:
          $(item_card_n0).css('width', w_5_14);
          $(item_card_n1).css('width', w_5_14);
          $(item_card_n2).css('width', w_5_14);
          $(item_card_n0+'.item-card--height').css('width', w_4_14);
          $(item_card_n1+'.item-card--height').css('width', w_4_14);
          $(item_card_n2+'.item-card--height').css('width', w_4_14);
          break;
        case 2:
          $(item_card_n0).css('width', w_5_13);
          $(item_card_n1).css('width', w_5_13);
          $(item_card_n2).css('width', w_5_13);
          $(item_card_n0+'.item-card--height').css('width', w_4_13);
          $(item_card_n1+'.item-card--height').css('width', w_4_13);
          $(item_card_n2+'.item-card--height').css('width', w_4_13);
          break;
        default:
          break;
        }
      }
    }
    return false;
  }
  

  function positionContentDine() {
    if ($(window).width() <= 767) {
      var height = 0;
      $('.list-dine .item-card__content').each(function () {
        height = $(this).height() + 16;
        $(this).parents('.item-card__content-bg').css('margin-bottom', height);
      });
    } else {
      $('.list-dine .item-card__content').each(function () {
        $(this).parents('.item-card__content-bg').css('margin-bottom', 'inherit');
      });
    }
  }

  //Activity
  function addJsHorizontalScroll() {
    if ($(window).width() <= 767) {
      $('.js-scroll-horizontal  .list-card').removeClass('scroll-main');
    } else {
      $('.js-scroll-horizontal .list-card').addClass('scroll-main');
    }
  }

  function positionContentActivity() {
    if ($(window).width() <= 767) {
      var height = -24;
      $('.list-activities .item-card .item-card__content').each(function () {
        height = -($(this).height() + 8) / 2;
        $(this).css('bottom', height);
        $(this).parents('.item-card__content-bg').css('margin-bottom', -height + 16);
      });
      $('.list-activities .item-card.width-x2 .item-card__content').each(function () {
        height = -($(this).height() + 16) / 2;
        $(this).css('bottom', height);
        $(this).parents('.item-card__content-bg').css('margin-bottom', -height + 16);
      });
    }
  }

  function positionContentCountryThingstodo() {
    if ($(window).width() <= 991) {
      var height = 0;
      $('.thingstodo__list .item-card:not(.no-has-image) .item-card__content').each(function () {
        height = $(this).outerHeight() - 60;
        $(this).parents('.item-card__content-bg').css('margin-bottom', height );
      });
    } else {
      $('.thingstodo__list .item-card:not(.no-has-image) .item-card__content').each(function () {
        height = $(this).outerHeight() - 60;
        $(this).parents('.item-card__content-bg').css('margin-bottom', 'inherit' );
      });
    }
  }

  //FeaturedOffers
  function positionContentFeaturedOffers() {
    if ($(window).width() <= 767) {
      var height = -24;
      $('.list-featured-offers .item-card__content').each(function () {
        height = -($(this).height() + 16) / 2;
        $(this).css('bottom', height);
        $(this).parents('.item-card__content-bg').css('margin-bottom', -height);
      });
    }
  }

   
  //   dine-ws
  function positionContentDineWs() {
    if ($(window).width() <= 767) {
      var height = -24;
      $('.list-dine-ws .item-card .item-card__content').each(function () {
        height = -($(this).height() + 8) / 2;
        $(this).css('bottom', height);
        $(this).parents('.item-card__content-bg').css('margin-bottom', -height);
      });
    }
  }

  //   venus
  function positionContentVenus() {
    if ($(window).width() <= 767) {
      var height = 0;
      $('.venues__list-card .card-venues .card-venues__content').each(function () {
        height = -$(this).height() + 40;
        $(this).parents('.card-venues').css('margin-bottom', -height);
      });
    }
  }
  function positionContentVenusCountry() {
    if ($(window).width() <= 767) {
      var height = 0;
      $('.venues-country__list-card .card-venues .card-venues__content').each(function () {
        height = -$(this).height() + 24;
        $(this).parents('.card-venues').css('margin-bottom', -height);
      });
    }
  }
  
});






