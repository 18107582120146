$(function () {
  const $header = $('.js-header');
  const $mSocial = $('.m-social');
  const $btnToggle = $header.find('.js-toogle-sidebar');
  const $sidebar = $header.find('.js-sidebar');
  const $closeSidebar = $header.find('.js-close-sidebar');

  $(window).on('click', (e) => {
    if ($mSocial.hasClass('show') && $header.hasClass('show')) {
      if (!$(e.target).closest($btnToggle).length && !$(e.target).closest($sidebar).length) {
        $mSocial.removeClass('show');
        $header.removeClass('show').trigger('isHide');
      }
    }
  });

  $closeSidebar.on('click', () => {
    $mSocial.removeClass('show');
    $header.removeClass('show').trigger('isHide');
  });

  $btnToggle.on('click', () => {
    $mSocial.toggleClass('show');
    $header.toggleClass('show');

    if ($mSocial.hasClass('show') && $header.hasClass('show')) {
      $header.trigger('isShow');
    } else {
      $header.trigger('isHide');
    }
  });

});
