$(function() {

  const $videoContainer = $('.js-video');
  if (!$videoContainer.length){
    return;
  }
  const $desktopVideo = $videoContainer.find('.desktop');
  const $mobileVideo = $videoContainer.find('.mobile');
  const $btnVolumn = $('.home-hero-banner__icon-volumn');

  _resizePositionVolumn();
  _resizeAutoPlayVideo();

  function _resizeAutoPlayVideo(){
    if (window.matchMedia('(max-width: 767px)').matches) {
      // mobile
      $desktopVideo[0].pause();
      if (!$mobileVideo.closest('.js-video').data('stop-video')) {
        $mobileVideo[0].play();
      }
    } else {
      //desktop
      if (!$desktopVideo.closest('.js-video').data('stop-video')) {
        $desktopVideo[0].play();
      }
      $mobileVideo[0].pause();
    }
  }
  
  $(window).resize(() => {
    _resizePositionVolumn();
    _resizeAutoPlayVideo();
    
  });


  function _resizePositionVolumn(){
    let h_booking = $('.js-booking .js-booking-content').outerHeight();
    let position = h_booking/2 + 24;
    $btnVolumn.css({
      'bottom': position,
      'opacity': 1,
    });
  }

  $btnVolumn.click(function() {
    const $videoDesktop = $(this).closest('.js-video').find('video.desktop');
    const $videoMobile = $(this).closest('.js-video').find('video.mobile');
    let $activeVideo = $videoDesktop;
    
    if (window.matchMedia('(max-width: 767px)').matches) {
      $activeVideo = $videoMobile;
    }
    
    const curStatus = $activeVideo.prop('muted');
    $videoDesktop.prop('muted', !curStatus);
    $videoMobile.prop('muted', !curStatus);
    const $icon = $btnVolumn.find('i');
    $icon.removeClass('icon-unmute').removeClass('icon-mute');

    if (curStatus === false){
      $icon.addClass('icon-mute');
    } else {
      $icon.addClass('icon-unmute');
    }
    return false;
  });


  
  $videoContainer.click(function() {
    const $videoDesktop = $(this).closest('.js-video').find('video.desktop');
    const $videoMobile = $(this).closest('.js-video').find('video.mobile');

    if (!$(this).data('stop-video')) {
      $videoDesktop[0].pause();
      $videoMobile[0].pause();
      $(this).data('stop-video', true);
    } else {
      if (window.matchMedia('(max-width: 767px)').matches) {
        $videoMobile[0].play();
      } else {
        $videoDesktop[0].play();
      }
      $(this).data('stop-video', false);
    }
    
  });
  
 

});
