$(function(){
  $('.popup-left').slick(slick_popup_left);
  $('.arow-left').click(function(){
    $('.popup-left').slick('slickPrev');
  });
  $('.arow-right').click(function(){
    $('.popup-left').slick('slickNext');
  });
});

const slick_popup_left = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 5000,
  dots: false,
  arrows: true,
};

const $jsProductItem = $('.js-product-item');

$jsProductItem.on('click', (e) => {
  const thisElementModalID = $(e.currentTarget).data('bs-target');
  const $thisModal = $(thisElementModalID)
  const $modalBackdrop = $('.modal-backdrop');

  $thisModal.css('visibility', 'hidden');
  $modalBackdrop.css('visibility', 'hidden');
});


$('.modal').on('shown.bs.modal', function (e) {
  const $thisElement = $(e.currentTarget);
  const $popupLeft = $thisElement.find('.popup-left');
  const $modalBackdrop = $('.modal-backdrop');
  $popupLeft.slick('setPosition');
  $thisElement.css('visibility', '');
  $modalBackdrop.css('visibility', '');
  $popupLeft.slick('slickGoTo', 0)
});
$('.modal').on('hide.bs.modal', function (e) {
  const $thisElement = $(e.currentTarget);
  const $popupLeft = $thisElement.find('.popup-left');
  $popupLeft.slick('slickGoTo', 0)
});
