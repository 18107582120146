// import JpControllerBase from './JpControllerBase.js';
// import { TimelineMax, TweenMax } from 'gsap/all';
// import * as ScrollMagic from 'scrollmagic';
// import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
//
// function HorizontalScrollController($el, options) {
//   const _self = this;
//
//   _self.$wrapper = $el;
//
//   _self._initHorizontalScroll = _initHorizontalScroll.bind(_self);
//   _self._totalWidth = _totalWidth.bind(_self);
//   _self._hideBottomSpace = _hideBottomSpace.bind(_self);
//   _self._refreshHorizontalScroll = _refreshHorizontalScroll.bind(_self);
//   _self._updatePosition = _updatePosition.bind(_self);
//
//   JpControllerBase.call(_self, $el, options);
//
//   // let mediaQuery = window.matchMedia('(max-width: 768px)');
//
//   _self.controller = new ScrollMagic.Controller();
//   _self.$slideContainers = _self.$wrapper.find('.js-slide');
//   _self.$dropdownMenu = _self.$wrapper.find('.dropdown-menu');
//   _self.allowSettingTransform = true;
//   _self.$header = $('.js-header');
//
//   _self.$slideContainers.css({
//     'display': 'flex',
//     'width': '100%'
//   }).parent().css('overflow', 'hidden');
//
//   _self.$childItem = _self.$slideContainers.children();
//
//   if (_self.$slideContainers.find('.js-box').length > 0) {
//     _self.$childItem = _self.$slideContainers.find('.js-box').children();
//   } else {
//     _self.$childItem = _self.$slideContainers.children();
//   }
//
//   let totalWidth = 0;
//
//   // find width of every child item
//
//   function _totalWidth() {
//     _self.$childItem.each((index, item) => {
//       const $thisElement = $(item);
//       let thisElementWidth;
//
//       if ($thisElement.css('display') === 'none') {
//         thisElementWidth = 0;
//       } else {
//         thisElementWidth = $thisElement.outerWidth(true);
//       }
//
//       totalWidth += thisElementWidth;
//     });
//   }
//
//   _self._totalWidth();
//
//   _self.isOffset = _self.$el.attr('aria-offset-top');
//   const $hHeader = $('.js-header').outerHeight(true);
//
//   function _initHorizontalScroll() {
//     _self.horizontalSlide = new TimelineMax()
//       .to(_self.$slideContainers[0], 0.5, { x: '-25%' })
//       .to(_self.$slideContainers[0], 1, { x: -(totalWidth - _self.$slideContainers.width()) });
//
//     const sceneOption = {
//       triggerElement: _self.$wrapper[0],
//       triggerHook: 'onLeave',
//       duration: `${totalWidth}px`
//     };
//
//     if (_self.isOffset) {
//       sceneOption.offset = -$hHeader;
//     }
//
//     _self.$scene = new ScrollMagic.Scene(sceneOption)
//       .setPin(_self.$wrapper[0])
//       .setTween(_self.horizontalSlide)
//       .addTo(_self.controller);
//
//   }
//
//   _self._initHorizontalScroll();
//
//   function _hideBottomSpace(total, position) {
//     const $divParent = _self.$wrapper.parent();
//     let $nextParent = $divParent.next();
//     let $nextElement;
//
//     if ($nextParent.length) {
//       $nextParent = $divParent.next();
//     } else {
//       $nextParent = $divParent.parent().next();
//     }
//
//
//     if ($nextParent.hasClass('scrollmagic-pin-spacer')) {
//       $nextElement = $nextParent.children();
//     } else {
//       $nextElement = $nextParent;
//     }
//
//     // if ($nextElement.hasClass('custom-aos-animate')) {
//     //   if (_self.allowSettingTransform) {
//     //     $nextElement.css({
//     //       'transform': `translateY(-${(total - position).toFixed()}px) translateZ(0)`,
//     //       'transition-duration': '0s'
//     //     });
//     //   }
//     // } else {
//     //   $nextElement.css('transform', `translateY(-${(total - position).toFixed()}px) translateZ(0)`).addClass('custom-aos-animate');
//     //   _self.allowSettingTransform = false;
//     //   setTimeout(() => {
//     //     _self.allowSettingTransform = true;
//     //   }, 400);
//     // }
//
//     $nextElement.css({
//       'transform': `translateY(-${total - position}px) translateZ(0)`,
//       'transition-duration': '0s'
//     });
//
//   }
//
//   if (_self.$dropdownMenu.length) {
//     _self.$dropdownMenu.on('dropdown-change', () => {
//       _self._refreshHorizontalScroll();
//     });
//   }
//
//   function _updatePosition() {
//     _self.$scene.on('update', (event) => {
//       const start = event.startPos;
//       const end = event.endPos;
//       const current = event.scrollPos;
//       const total = end - start;
//
//       if (current >= start && current <= end) {
//         const position = current - start;
//         _self._hideBottomSpace(total, position);
//       } else {
//         _self._hideBottomSpace(0, 0);
//       }
//
//     });
//   }
//
//   _self._updatePosition();
//
//   function _refreshHorizontalScroll() {
//     _self.controller.destroy(true);
//     _self.controller = new ScrollMagic.Controller();
//     totalWidth = 0;
//     _self._totalWidth();
//     _self._initHorizontalScroll();
//     _self._updatePosition();
//   }
//
//   $(window).on('resize', () => {
//     if (window.matchMedia('(max-width: 768px)').matches) {
//       _self.controller.destroy(true);
//       _self.$wrapper.css( 'transform', '');
//     } else {
//       _self._refreshHorizontalScroll();
//     }
//   });
//
//   if (window.matchMedia('(max-width: 768px)').matches) {
//     _self.controller.destroy(true);
//   } else {
//     _self._refreshHorizontalScroll();
//   }
//
//   _self.$header.on('isShow', () => {
//     $('html, body').css({
//       overflow: 'hidden'
//     });
//     const slibarContentWidth = $('.sidebar__content').outerWidth(true);
//     _self.$wrapper.css({
//       'transform': `translateX(-${slibarContentWidth}px)`,
//       'transition': '.6s',
//     });
//   });
//
//   _self.$header.on('isHide', () => {
//     $('html, body').css({
//       overflow: ''
//     });
//     _self.$wrapper.css({
//       'transform': 'translateX(0px)',
//       'transition': '.6s',
//     });
//   });
//
// }
//
// HorizontalScrollController.prototype = Object.create(JpControllerBase.prototype);
//
// HorizontalScrollController.prototype.render = function (state) {
//   const _self = this;
//
// };
//
// export default HorizontalScrollController;
//
