(function ($) {

  $(document).ready(() => {
    const $offerContainer = $('.js-offers-box');
    const $offerCard = $offerContainer.children();
    function _setMaxHeight() {
      if (!$offerContainer.length) {
        return;
      }

      let totalHeight = 0;
      let maxHeightItem = 0;
      $offerCard.each((index, item) => {
        const $thisElement = $(item);
        const thisHeight = $thisElement.outerHeight(true);
        totalHeight += thisHeight;
        if ($thisElement.outerHeight() > maxHeightItem) {
          maxHeightItem = $thisElement.outerHeight();
        }
      });
      totalHeight = totalHeight/3;
      if (totalHeight < maxHeightItem){
        totalHeight = maxHeightItem;
      }
      $offerContainer.css({
        'max-height': `${totalHeight}px`
      });

      function isOverflow(element) {
        return element.scrollWidth > element.clientWidth;
      }

      while (isOverflow($offerContainer[0])) {
        totalHeight += 10;
        $offerContainer.css({
          'max-height': `${totalHeight}px`
        });
      }
    }

    if (!window.matchMedia('(max-width: 767px)').matches) {
      _setMaxHeight();
    }

    $(window).on('resize', () => {
      if (!window.matchMedia('(max-width: 767px)').matches) {
        _setMaxHeight();
      } else {
        $offerContainer.css({
          'max-height': 'unset'
        });
      }
    });

  });
}(jQuery));
