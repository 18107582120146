import './api-caller';
import '../../node_modules/gsap/dist/gsap.min';
import '../../node_modules/scrollmagic/scrollmagic/minified/ScrollMagic.min';
import '../../node_modules/scrollmagic-plugin-gsap';
import './components/navbar';
import '../../node_modules/slick-carousel/slick/slick.min.js';
import './components/swiper-mobile';
import './components/destinations';
import './components/card';
import './components/rooms';
import './matchHeight';
import './jpCom';
import './Header';
import './Offers';
import './Video';
import './Error-page';
import './ga4';
import './updateLinkCta';


$(function() {
  const $horizontalScroll = $('.js-horizontal-scroll');
  const $booking = $('.js-booking');
  const $map = $('.js-map');
  const $imageLibrary = $('.js-image-library');
  const $dineWs = $('.js-dine-ws');
  const $howToPplay = $('.js-how-to-play');
  const $activities = $('.js-activities');
  const $newsletter = $('.js-newsletter');
  const $gallery = $('.js-gallery');
  const $listRewards = $('.js-rewards-staycation-list');
  const $listRelatedOffers = $('.js-list-related-offers');
  const $mobileGame = $('.js-mbg-content');

  AOS.init();

  if ($listRewards.length) {
    $listRewards.matchHeight({ selectors: [ '.js-img', '.js-title', '.js-desc' ] });
    $listRewards.matchHeight('_setHeight');
  }
  if ($listRelatedOffers.length) {
    $listRelatedOffers.matchHeight({ selectors: [ '.js-title', '.js-desc' ] });
    $listRelatedOffers.matchHeight('_setHeight');
  }

  if ($booking.length) {
    $booking.jpCom();
  }

  if ($horizontalScroll.length) {
    $horizontalScroll.jpCom();
  }

  if ($map.length) {
    $map.jpCom();
  }

  if ($imageLibrary.length) {
    Array.from($imageLibrary).forEach(imageLibraryItem => {
      if (imageLibraryItem.classList.contains('image-library__gallery')){
        $(imageLibraryItem).jpCom({ isBreakSwiperInMobile: false, navigation: true });
      } else {
        $(imageLibraryItem).jpCom({ isBreakSwiperInMobile: true, navigation: true });
      }
    });
  }

  if ($dineWs.length) {
    $dineWs.jpCom({ navigation: true });
  }
  if ($howToPplay.length) {
    $howToPplay.jpCom({ navigation: true });
  }

  if ($mobileGame.length){
    $mobileGame.jpCom();
  }

  if ($activities.length) {
    $activities.jpCom();
  }

  if ($newsletter.length) {
    $newsletter.jpCom();
  }

  if ($gallery.length) {
    $gallery.jpCom({ navigation: true });
  }

});
