(function ($) {
  $(document).ready(() => {
    const $element = $('.js-header');
    const $dropDown = $element.find('.js-dropdown');
    const $itemDropdown = $dropDown.find('.dropdown-item');
    const $thisButton = $dropDown.find('.dropdown-toggle');
    const $loginBtn = $element.find('.nav-item--login .btn.btn-primary');

    $itemDropdown.each((index, item) => {
      const $thisElement = $(item);
      const shortText = $thisElement.data('shorten');

      if ($thisElement.hasClass('active')) {
        $thisButton.html(shortText);
      }
    });

    $itemDropdown.click((item) => {
      const $thisElement = $(item.currentTarget);
      const shortText = $thisElement.data('shorten');
      $thisButton.html(shortText);

      $itemDropdown.removeClass('active');
      $thisElement.addClass('active');
    });

    function _setReturnURL() {
      const $thisBtnUrl = $loginBtn.attr('href');
      const $thisPageUrl = window.location.href;
      const newRedirectUrl = $thisBtnUrl + '?returnURL=' + encodeURIComponent($thisPageUrl);

      $loginBtn.attr('href', newRedirectUrl);

    }

    _setReturnURL();

  });
}(jQuery));
