import JpControllerBase from './JpControllerBase.js';

function DateRangePickerController($el, options) {
  const _self = this;

  _self._handleStartAndEndData = _handleStartAndEndData.bind(_self);

  JpControllerBase.call(_self, $el, options);

  _self.$input = _self.$el.find('.js-input');
  _self.$render = _self.$el.find('.js-render');
  _self.$startRender = _self.$el.find('.js-start-date-render');
  _self.$endRender = _self.$el.find('.js-end-date-render');

  const daysOfWeek = [ 'S', 'M', 'T', 'W', 'T', 'F', 'S' ];
  const fullMonthNames = [ 'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
    'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
  ];

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  let startAndEndDate;

  // date range picker options
  let dateRangePickerOptions = {
    ignoreReadonly: true,
    autoUpdateInput: true,
    autoApply: true,
    linkedCalendars: false,
    parentEl: _self.$render,
    minDate: today,
    startDate: today,
    endDate: tomorrow,
    locale: {
      daysOfWeek: daysOfWeek,
      monthNames: fullMonthNames,
      format: 'DD MMM YYYY'
    }
  };

  // init date range picker
  _self.$input.daterangepicker(dateRangePickerOptions);
  const controller = _self.$input.data('daterangepicker');
  const prototype = Object.getPrototypeOf(controller);

  let oldClickDate = prototype.clickDate;

  prototype.clickDate = function (e) {
    const thisElement = $(e.currentTarget);
    if (thisElement.hasClass('start-date')) {
      return;
    }
    oldClickDate(e);
  };

  // init custom date range picker
  _self.$input.daterangepicker(dateRangePickerOptions);

  const controller2 = _self.$input.data('daterangepicker');

  oldClickDate = oldClickDate.bind(controller2);

  _self.$el.find('.drp-calendar.right').hide();
  _self.$el.find('.drp-calendar.left').addClass('single');

  _self.$input.on('showCalendar.daterangepicker', (event)=> {
    const $optionAvailable = _self.$el.find('.drp-calendar.left .table-condensed td.available');
    const $allTdOption = _self.$el.find('td');

    $optionAvailable.hover((e) => {
      const $thisElement = $(e.currentTarget);
      const $nextElement = $thisElement.next();
      const $nextElementInNextRow =  $thisElement.parent().next().find(':first-child');

      $optionAvailable.removeClass('active start-date end-date in-range');

      if ($thisElement.is(':last-child')) {
        $nextElementInNextRow.addClass('hover');
      } else {
        $nextElement.addClass('hover');
      }

    }, (e) => {
      const $thisElement = $(e.currentTarget);
      const $nextElement = $thisElement.next();
      const $nextElementInNextRow =  $thisElement.parent().next().find(':first-child');

      if ($thisElement.is(':last-child')) {
        $nextElementInNextRow.removeClass('hover');
      } else {
        $nextElement.removeClass('hover');
      }
    });



  });

  _self.$input.on('apply.daterangepicker', () => {
    _self._handleStartAndEndData();
  });

  function _handleStartAndEndData() {
    startAndEndDate = _self.$input.val();

    startAndEndDate = startAndEndDate.split('-');

    const trimItem = startAndEndDate.map((item)=> {
      let newItem = item.trim();
      newItem = moment(newItem, 'DD MMM YYYY').format('DD MMM YYYY');
      return newItem;
    });

    _self.$startRender.empty();
    _self.$endRender.empty();

    _self.$startRender.append(`<span>${trimItem[0]}</span>`);
    _self.$endRender.append(`<span>${trimItem[1]}</span>`);

  }

  _self._handleStartAndEndData();

  _self.$input.on('show.daterangepicker', ()=> {
    const $thisCalendar = _self.$render.find('.drp-calendar.left');
    const thisCalendarTop = $thisCalendar.offset().top;
    const thisCalendarBottom = thisCalendarTop + $thisCalendar.outerHeight(true);
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();

    const isInViewPort = viewportTop < thisCalendarTop && viewportTop < thisCalendarBottom
      && viewportBottom > thisCalendarTop && viewportBottom > thisCalendarBottom;

    const space = viewportTop + (thisCalendarBottom - viewportBottom) + 50;

    if (!isInViewPort) {
      $('html, body').animate({
        scrollTop: space
      }, 500);
    }
  });

}

DateRangePickerController.prototype = Object.create(JpControllerBase.prototype);

DateRangePickerController.prototype.render = function () {
  const _self = this;
};

export default DateRangePickerController;

