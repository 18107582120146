(function ($) {

  $(document).ready(() => {
    const $linkPlayGame = $('.js-btn-play-game');
    if ($linkPlayGame.length) {
      $(window).on('resize', updateLink);

      function updateLink() {
        $linkPlayGame.each( (i, item) => {
          var $item = $(item);
          if (window.matchMedia('(max-width: 991px)').matches) {
            if ($item.data('linkgame-mobile')) {
              $item.attr('href', $item.data('linkgame-mobile'));
            }
          } else {
            if ($item.data('linkgame-desktop')) {
              $item.attr('href', $item.data('linkgame-desktop'));
            }
          }
        });
      }

      updateLink();
    }
  });
}(jQuery));
