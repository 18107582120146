$(function () {
  let swiperOption = {
    allowTouchMove: true,
    slidesPerView: 'auto'
  };
  const $jsSwiperMobile = $('.js-swiper-mobile');
  if ($jsSwiperMobile.length === 0) return;
  const $jsSwiperContainer = $jsSwiperMobile.find('.js-swiper-container');

  handleSlickOnMobile();
  $(window).on('resize', () => {
    handleSlickOnMobile();
  });

  function handleSlickOnMobile() {
    if (window.matchMedia('(max-width: 767px)').matches) {
      if (!$jsSwiperContainer.hasClass('swiper-initialized')) {
        $jsSwiperContainer.toArray().forEach(item => {
          const swiper = new Swiper(item, swiperOption);
          $jsSwiperContainer.addClass('custom-swiper');
        });
      }
    } else {
      if ($jsSwiperContainer.hasClass('swiper-initialized')) {
        $jsSwiperContainer.toArray().forEach(item => {
          const swiper = item.swiper;
          swiper.destroy(true, true);
          $jsSwiperContainer.removeClass('custom-swiper');
        });
      }
    }
  } 
});