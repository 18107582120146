import JpControllerBase from './JpControllerBase.js';

function MapController($el, options) {
  const _self = this;

  _self._initMap = _initMap.bind(_self);

  JpControllerBase.call(_self, $el, options);

  _self.$data = _self.$el.data('map');

  const destinationData = _self.$data.slice(',');
  const singaporeCoordinate = [ 1.364917, 103.822872 ];
  const hotelLatitude = Number(`${ _self.$data.length > 0 ?  destinationData[0] : singaporeCoordinate[0] }`);
  const hotelLongitude = Number(`${ _self.$data.length > 0 ?  destinationData[1] : singaporeCoordinate[1] }`);

  const marker = new google.maps.Marker({
    position: {
      lat: hotelLatitude,
      lng: hotelLongitude,
    },
    title: 'Msocial Hotel'
  });

  let map;

  const infoMap = {
    zoom: 13,
    center: {
      lat: hotelLatitude,
      lng: hotelLongitude,
    },
    mapTypeId: 'terrain'
  };


  _self._initMap();

  function _initMap() {
    map = new google.maps.Map(document.getElementById('map'), {
      zoom: infoMap.zoom,
      center: infoMap.center,
      mapTypeId: infoMap.mapTypeId,
    });

    marker.setMap(map);
  }

}

MapController.prototype = Object.create(JpControllerBase.prototype);

MapController.prototype.render = function(state) {
  const _self = this;
};

export default MapController;
