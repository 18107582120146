import JpControllerBase from './JpControllerBase.js';

function SwiperController($el, options) {
  const _self = this;

  _self._handleSlickOnMobile = _handleSlickOnMobile.bind(_self);
  // _self._checkMouseDirection = _checkMouseDirection.bind(_self);
  // _self._handleMouseOver = _handleMouseOver.bind(_self);

  JpControllerBase.call(_self, $el, options);

  _self.$swiperContainer = _self.$el.find('.js-swiper-container');

  _self.swiperContainer = null;
  _self.hoverLeft = null;
  _self.isBreakSwiperInMobile = false;
  const delayTime = 1000;

  if (options && options.isBreakSwiperInMobile) {
    _self.isBreakSwiperInMobile = options.isBreakSwiperInMobile;
  }

  let swiperOption = {
    allowTouchMove: true,
    slidesPerView: 'auto',
    freeMode: true,
    speed: delayTime,
    // mousewheel: {
    //   releaseOnEdges: true,
    // },
  };

  if (options && options.navigation) {
    _self.$swiperContainer.append(`
    <div class="arrow arrow-left"><i class="icon icon-global-arrow-left"></i></div>
    <div class="arrow arrow-right"><i class="icon icon-global-arrow-right"></i></div>
    `);

    swiperOption.navigation = {
      prevEl: '.arrow-left',
      nextEl: '.arrow-right',
    };
  }

  if (_self.isBreakSwiperInMobile) {
    if (!window.matchMedia('(max-width: 767px)').matches) {
      _self.swiperContainer = new Swiper(_self.$swiperContainer[0], swiperOption);
      _self.$swiperContainer.addClass('custom-swiper');
    }
  } else {
    _self.swiperContainer = new Swiper(_self.$swiperContainer[0], swiperOption);
    _self.$swiperContainer.addClass('custom-swiper');
  }

  function _handleSlickOnMobile() {
    if (_self.isBreakSwiperInMobile) {
      if (window.matchMedia('(max-width: 767px)').matches) {
        if (_self.$swiperContainer.hasClass('swiper-initialized')) {
          _self.swiperContainer.destroy(true, true);
          _self.$swiperContainer.removeClass('custom-swiper');
        }
      } else {
        if (!_self.$swiperContainer.hasClass('swiper-initialized')) {
          _self.swiperContainer = new Swiper(_self.$swiperContainer[0], swiperOption);
          _self.$swiperContainer.addClass('custom-swiper');
        }
      }
    }
  }

  function checkSwiperContainerOverflow(className){
    _self.$swiperContainer.parents(className).each(function(){
      const swiper_wrapper = $(this).find('.swiper-wrapper');
      if (swiper_wrapper[0].scrollWidth <= $(this)[0].scrollWidth){
        swiper_wrapper.css('justify-content', 'center');
      } else {
        swiper_wrapper.css('justify-content', 'start');
      }
    });
  }
  checkSwiperContainerOverflow('.js-image-library');
  checkSwiperContainerOverflow('.js-gallery');

  $(window).on('resize', () => {
    _self._handleSlickOnMobile();
    checkSwiperContainerOverflow('.js-image-library');
    checkSwiperContainerOverflow('.js-gallery');
  });

}

SwiperController.prototype = Object.create(JpControllerBase.prototype);

SwiperController.prototype.render = function () {
  const _self = this;

};

export default SwiperController;
