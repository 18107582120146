(function($) {
  const COMPONENT_DATA_ATTR = 'jqp-match-height';
  const COMPONENT_NAME = 'MatchHeightController';
  const COMPONENT_ATTR = 'jqp-component';
 
  function MatchHeight($el, options) {
    const _self = this;
    _self.options = options || {};
    _self.$el = $el;
    _self.timer = null;

    const myResizeContent = function resizeContent() {
      if (!_self.timer) {
        const setHeightInTimeout = function () {
          _self.setHeight();
          _self.timer = null;
        };

        _self.timer = setTimeout(setHeightInTimeout, 500);
      }
    };
    document.fonts.ready.then(function () {
      myResizeContent();
    });
    window.addEventListener('resize', myResizeContent);
  }

  MatchHeight.prototype = {
    setHeight: function () {
      const _self = this;
      if (_self.options.cardPerRow) {
        _self._setHeightByRow();
      }
      else {
        _self._setHeight();
      }
    },

    _setHeight: function() {
      const _self = this;

      const selectors = _self.options.selectors;

      selectors.forEach(function (el) {
        let maxHeight = 0;
        const $items = _self.$el.find(el);
        $items.height('auto');

        $items.each((index, el) => {
          const thisHeight = $(el).height();

          if (thisHeight > maxHeight) { maxHeight = thisHeight; }
        });

        $items.height(maxHeight);
        if (!_self.$el.hasClass('js-match-height-mobile')){
          const mediaQuery = window.matchMedia('(max-width: 768px)');
          if (mediaQuery.matches) {
            $items.height('auto');
          }
        }
      });

    },
    _setHeightByRow: function() {
      const _self = this;
      const selectors = _self.options.selectors;
      const cardPerRow = _self.options.cardPerRow;

      selectors.forEach(function (el) {
        const cardGroups = [];
        const items = _self.$el.find(el);
        let curGroup = { items: [] };

        items.each(function(index, el) {
          if (index % cardPerRow === 0) {
            curGroup = { items: [] };
            cardGroups.push(curGroup);
          }
          curGroup.items.push(el);
        });


        cardGroups.forEach(function(group){
          let maxHeight = 0;
          const items = group.items;

          items.forEach(function (item) {
            $(item).height('auto');
          });

          items.forEach(function(item) {
            const heightCurrent = $(item).height();
            if (maxHeight < heightCurrent) { maxHeight = heightCurrent; }
          });

          items.forEach(function(item) {
            $(item).height(maxHeight);
            const mediaQuery = window.matchMedia('(max-width: 768px)');
            if (mediaQuery.matches) {
              $(item).height('auto');
            }
          });
        });
      });
    },
  };

  $.fn.matchHeight = function() {
    const $this = this;
    const options = arguments[0];
    const args = Array.prototype.slice.call(arguments, 1);
    const length = $this.length;

    for (let i = 0; i < length; i++) {
      const $element = $($this[i]);
      if (!$element.data(COMPONENT_ATTR) || $element.data(COMPONENT_ATTR) !== COMPONENT_NAME) {
        throw Error('Data data-jqp-component not found : ' + COMPONENT_NAME);
      }

      if (typeof options === 'object' || typeof options === 'undefined') {
        $element.data(COMPONENT_DATA_ATTR, new MatchHeight($element, options));
      } else {
        // options is string
        const methodName = arguments[0];
        const controller = $element.data(COMPONENT_DATA_ATTR);
        return $element.data(COMPONENT_DATA_ATTR)[methodName].apply(controller, args);
      }
    }

    return $this;
  };
})(jQuery);
