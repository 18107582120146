import JpControllerBase from './JpControllerBase.js';

function SingleSelect2Controller($el, options) {
  const _self = this;

  JpControllerBase.call(_self, $el, options);

  _self.$customSelect2 = _self.$el.find('.js-custom-select2');
  _self.$placeholderText2 = _self.$el.data('placeholder');
  _self.$selectElement = _self.$el.find('.js-select');
  _self.$dropDownBox = _self.$el.find('.js-dropdown-box');
  _self.$allOptionValue = _self.$el.find('option');


  _self.select2Options = {
    dropdownParent: _self.$dropDownBox,
    dropdownAutoWidth: true,
    minimumResultsForSearch: Infinity,
    closeOnSelect: true,
    multiple: false,
    placeholder: _self.$placeholderText2
  };

  _self.$selectElement.select2(_self.select2Options);

  _self.$selectElement.on('change', () => {
    const selectValue = _self.$selectElement.select2('data')[0].text;

    _self.$allOptionValue.each((index, item) => {
      const $thisElement = $(item);
      const thisElementValue = $thisElement.attr('value');
      const thisHotelCode = $thisElement.attr('data-hotel-code');

      if (thisElementValue === selectValue) {
        _self.$selectElement.data('hotel-code', thisHotelCode);
      }

    });
  });

  $(window).on('load', () => {
    const curValue = _self.$selectElement.val();
    _self.$selectElement.val(curValue).trigger('change');
  });

}

SingleSelect2Controller.prototype = Object.create(JpControllerBase.prototype);

SingleSelect2Controller.prototype.render = function () {
  const _self = this;
};

export default SingleSelect2Controller;
