// import JpControllerBase from './JpControllerBase.js';
//
// function SlickController($el, options) {
//   const _self = this;
//
//   _self._handleWheel = _handleWheel.bind(_self);
//   _self._isLastSlide = _isLastSlide.bind(_self);
//   _self._isFirstSlide = _isFirstSlide.bind(_self);
//   _self._setSpacingForSlide = _setSpacingForSlide.bind(_self);
//   _self._removeSpacingForSlide = _removeSpacingForSlide.bind(_self);
//   _self._handleSlickOnMobile = _handleSlickOnMobile.bind(_self);
//
//   JpControllerBase.call(_self, $el, options);
//
//   _self.$slickContainer = _self.$el.find('.js-slide');
//
//   let slickOption = {
//     slidesToShow: 5,
//     variableWidth: true,
//     infinite: false,
//     prevArrow: false,
//     nextArrow: false,
//     slidesToScroll: 1,
//   };
//
//   // const breakSlick = [
//   //   {
//   //     breakpoint: 767,
//   //     settings: 'unslick'
//   //   }
//   // ];
//
//   let isBreakSlickInMobile = false;
//
//   if (options) {
//     isBreakSlickInMobile = options.breakSlickInMobile;
//   }
//
//   let oldSpacing = 0;
//
//   _self.$slickContainer.slick(slickOption);
//
//   if (window.matchMedia('(max-width: 767px)').matches) {
//     if (isBreakSlickInMobile) {
//       _self.$slickContainer.slick('unslick');
//     }
//   }
//
//
//   _self.$slickContainer.on('wheel', (e) => {
//     if (_self.$slickContainer.hasClass('slick-initialized')) {
//       _self._handleWheel(e);
//     }
//   });
//
//   // _self.$slickContainer.on('afterChange', () => {
//   //   if (_self._isLastSlide()) {
//   //     _self._setSpacingForSlide();
//   //   }
//   //
//   //   if (_self._isFirstSlide()) {
//   //     _self._removeSpacingForSlide();
//   //   }
//   // });
//
//   _self.$slickContainer.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
//     console.log(1, event);
//     console.log(2, slick);
//     console.log(3, currentSlide);
//     console.log(4, nextSlide);
//
//     if (nextSlide > 6) {
//       console.log(123)
//     }
//   });
//
//   function _handleWheel(e) {
//
//     if (e.originalEvent.deltaY > 0) {
//       if (!_self._isLastSlide()) {
//         e.preventDefault();
//         _self.$slickContainer.slick('slickNext');
//         // console.log('prevent and go down');
//       }
//     } else {
//       if (!_self._isFirstSlide()) {
//         e.preventDefault();
//         _self.$slickContainer.slick('slickPrev');
//         // console.log('prevent and go up');
//       }
//     }
//   }
//
//   function _isLastSlide() {
//     const $slickItem = _self.$el.find('.slick-slide');
//
//     const $lastElement = $slickItem.last();
//     const lastElementLeft = $($lastElement)[0].getBoundingClientRect().left;
//     const lastElementRight = lastElementLeft + $lastElement.outerWidth(true);
//
//     // container of slick
//     const containerLeft = $(_self.$slickContainer)[0].getBoundingClientRect().left;
//     const containerRight = containerLeft + (_self.$slickContainer.outerWidth(true));
//
//
//     return containerLeft <= lastElementLeft && containerRight >= lastElementRight;
//   }
//
//   function _isFirstSlide() {
//     const $slickItem = _self.$el.find('.slick-slide');
//
//     const $firstElement = $slickItem.first();
//
//     const firstElementLeft = $($firstElement)[0].getBoundingClientRect().left;
//     const firstElementRight = firstElementLeft + $firstElement.outerWidth(true);
//     // container of slick
//     const containerLeft = $(_self.$slickContainer)[0].getBoundingClientRect().left;
//     const containerRight = containerLeft + (_self.$slickContainer.outerWidth(true));
//
//     return containerLeft <= firstElementLeft && containerRight >= firstElementRight;
//   }
//
//   function _setSpacingForSlide() {
//     const $slides = _self.$el.find('.slick-slide');
//
//     const $lastElement = $slides.last();
//     const lastElementLeft = $($lastElement)[0].getBoundingClientRect().left;
//     const lastElementRight = lastElementLeft + $lastElement.outerWidth(true);
//
//     const containerLeft = $(_self.$slickContainer)[0].getBoundingClientRect().left;
//     const containerRight = containerLeft + (_self.$slickContainer.outerWidth(true));
//     let spacing = containerRight - lastElementRight;
//
//     $slides.closest('.slick-track').css({
//       marginLeft: `${spacing + oldSpacing}px`,
//       transition: '.4s'
//     });
//     oldSpacing += spacing;
//
//   }
//
//   function _removeSpacingForSlide() {
//     const $slides = _self.$el.find('.slick-slide');
//
//     const $firstElement = $slides.first();
//
//     const firstElementLeft = $($firstElement)[0].getBoundingClientRect().left;
//     const firstElementRight = firstElementLeft + $firstElement.outerWidth(true);
//     // container of slick
//     const containerLeft = $(_self.$slickContainer)[0].getBoundingClientRect().left;
//
//     const currentMarginLeft = parseInt($slides.closest('.slick-track').css('margin-left'));
//     let removeSpacing = firstElementLeft - containerLeft;
//
//     if (firstElementLeft > containerLeft) {
//       $slides.closest('.slick-track').css({
//         // marginLeft: `${currentMarginLeft - removeSpacing}px`,
//         marginLeft: '0px',
//         transition: '.5s'
//       });
//     }
//     oldSpacing = 0;
//   }
//
//   function _handleSlickOnMobile() {
//     if (isBreakSlickInMobile) {
//       if (window.matchMedia('(max-width: 767px)').matches) {
//         if (_self.$slickContainer.hasClass('slick-initialized')) {
//           _self.$slickContainer.slick('unslick');
//         }
//       } else {
//         if (!_self.$slickContainer.hasClass('slick-initialized')) {
//           _self.$slickContainer.slick(slickOption);
//         }
//       }
//     }
//
//     const $slides = _self.$el.find('.slick-slide');
//     $slides.closest('.slick-track').css({
//       marginLeft: '0px',
//       transition: '.5s'
//     });
//     _self.$slickContainer.slick('slickGoTo', 0);
//
//   }
//
//   $(window).on('resize', () => {
//     _self._handleSlickOnMobile();
//   });
//
// }
//
// SlickController.prototype = Object.create(JpControllerBase.prototype);
//
// SlickController.prototype.render = function () {
//   const _self = this;
//
// };
//
//
// SlickController.prototype.unSlick = function () {
//   const _self = this;
//
//   if (_self.$slickContainer.hasClass('slick-initialized')) {
//     _self.$slickContainer.slick('unslick');
//   }
// };
//
// export default SlickController;
