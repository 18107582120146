(function( $) {
  const APICaller = function() {
    APICaller.prototype.call = function(url, method, param, handleCallApiSuccess, handleCallApiFail) {
      $.ajax({
        contentType: method.toUpperCase() === 'POST' ? 'application/json' : '',
        url: url,
        dataType: 'json',
        data: JSON.stringify(param),
        type: method || 'GET',
        async: true,
        success: function (response) {
          const { errorCode, errorMessage } = response;

          if (errorCode) {
            handleCallApiFail(errorMessage);
            return;
          }

          handleCallApiSuccess(response);
        },
        error: function(err) {
          handleCallApiFail(err);
        },
      });
    };
  };

  window.APICaller = window.APICaller || {};
  window.APICaller = new APICaller();
})(jQuery);
