import JpControllerBase from './JpControllerBase.js';

function ActivitiesController($el, options) {
  const _self = this;

  _self._renderCard = _renderCard.bind(_self);
  _self._firstRenderCard = _firstRenderCard.bind(_self);

  JpControllerBase.call(_self, $el, options);

  const $activities = _self.$el;
  const $swiperContainer = $activities.find('.js-swiper');
  // const $slideContent = $activities.find('.js-content');
  const $listActivities = $activities.find('.list-activities');
  const $activitiesData = $activities.find('.js-data');
  const $dropdownItem = $activities.find('.dropdown-item');
  // const $itemCard = $activities.find('.item-card');
  const $showText = $activities.find('.span-hot');


  if ($dropdownItem.length) {
    _self._firstRenderCard();
  } else {
    $listActivities.html('<div class="swiper-wrapper js-content">'+ $activitiesData.html() + '</div>');

    const $itemCard = $listActivities.find('.item-card');

    $itemCard.addClass('swiper-slide');
    $swiperContainer.jpCom({ isBreakSwiperInMobile: true, navigation: true });
  }

  $dropdownItem.on('click', (item) => {
    const $thisElement = $(item.target);
    const thisElementID = $thisElement.data('activity');
    const thisElementText = $thisElement.text();

    if (!$thisElement.hasClass('active')) {
      $dropdownItem.removeClass('active');
      $thisElement.addClass('active');
      $showText.html(thisElementText);
      _self._renderCard(thisElementID);
    }

  });

  function _renderCard(id) {
    $listActivities.html('<div class="swiper-wrapper js-content">'+ $activitiesData.html() + '</div>');

    const $itemCard = $listActivities.find('.item-card');

    $itemCard.each((i, e) => {
      const $thisCard = $(e);
      const cardID = $thisCard.data('activity');

      $thisCard.addClass('swiper-slide');

      if (cardID !== id) {
        $thisCard.remove();
      }
    });

    $swiperContainer.jpCom({ isBreakSwiperInMobile: true, navigation: true });
  }

  function _firstRenderCard() {
    $dropdownItem.each((index, item) => {
      const $thisElement = $(item);
      const thisElementText = $thisElement.text();

      if ($thisElement.hasClass('active')) {
        $showText.html(thisElementText);
        const thisId = $thisElement.data('activity');
        _self._renderCard(thisId);
      }
    });
  }


}

ActivitiesController.prototype = Object.create(JpControllerBase.prototype);

ActivitiesController.prototype.render = function () {
  const _self = this;

};

export default ActivitiesController;
