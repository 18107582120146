$( document ).ready(function() {
  function setHeightErrorPage() {
    const errorPage = $('.js-introduction');
    
    const logoHeight = $('.logo-region').length ? $('.logo-region')[0].offsetHeight : 0;
    const footerHeight = $('.footer').length ? $('.footer')[0].offsetHeight : 0;
    const totalHeight = logoHeight + footerHeight;
    errorPage.closest('main').css({ 'min-height': `calc(100vh - ${totalHeight}px)` });
  }
  setHeightErrorPage();
  $(window).on('resize', setHeightErrorPage);
});