import JpControllerBase from './JpControllerBase.js';

function BookingController($el, options) {
  const _self = this;

  _self._leadingZero = _leadingZero.bind(_self);
  _self._handleGetValue = _handleGetValue.bind(_self);
  _self._handleInOutViewPort = _handleInOutViewPort.bind(_self);
  _self._spacingBottomBooking = _spacingBottomBooking.bind(_self);

  JpControllerBase.call(_self, $el, options);

  const buttonText = _self.$el.find('.js-button-text').text();

  //create BookingButton
  _self.$el.append(`
    <div class="booking__fixed-button">
      <span class="icon-up-arrow"></span>
      <div>${buttonText}</div>
    </div>
  `);

  _self.$customSelect2 = _self.$el.find('.js-custom-select2');
  _self.$select2Icon = _self.$el.find('.js-select2-icon');
  _self.$dateRangePicker = _self.$el.find('.js-date-time-picker');
  _self.$inputNumber = _self.$el.find('.js-input-number');
  _self.$formValidation = _self.$el.find('.js-form');
  _self.$header = $('header.header.js-header');
  _self.bookingHeight = _self.$el.find('.booking__content').outerHeight(true);
  _self.bookingTop = _self.$el.offset().top - (_self.bookingHeight/2);
  _self.$fixedButton = _self.$el.find('.booking__fixed-button');
  _self.$bookingContent = _self.$el.find('.js-booking-content');
  _self.$btnBooking = _self.$el.find('.js-submit-button');
  const bookingUrl = _self.$el.data('booking-url');

  const defaultAdultsNumber = '02';
  const defaultChildrenNumber = '00';

  const customRules =  {
    'hotelcode': {
      required: true,
    },
    'date-range': {
      required: true,
    },
    'adults': {
      required: true,
      minStrict: 0,
    },
    'children': {
      required: false,
    },
    'promocode': {
      required: false,
    }
  };

  const customMessages = {
    'hotelcode': 'Please select a destination',
    'adults': 'Adult number must be greater than zero',
  };

  let BOOKING_URL = '';
  let DATA_GTM = {};

  _self.$customSelect2.jpCom();

  _self.$dateRangePicker.jpCom();

  _self.$formValidation.validate({
    onfocusout: false,
    onkeyup: false,
    rules: customRules,
    messages: customMessages,
    errorPlacement: function(error, element) {
      const thisElement = $(element);
      const placement = thisElement.data('error');
      const placementParent = $(element).closest('.js-error-container');
      const nearestPlacement = placementParent.find(placement);

      if (nearestPlacement) {
        $(nearestPlacement).append(error);
        placementParent.addClass('error');
        setTimeout(() => {
          nearestPlacement.empty();
          placementParent.removeClass('error');
        }, 5000);
      } else {
        error.insertAfter(element);
      }
    },
  });

  $.validator.addMethod('minStrict', function (value, el, param) {
    return value > param;
  });

  $(_self.$inputNumber[0]).val(defaultAdultsNumber);
  $(_self.$inputNumber[1]).val(defaultChildrenNumber);

  _self.$customSelect2.on('click', ()=> {
    _self.$select2Icon.toggleClass('active');
  });

  _self.$inputNumber.on('input', (e) => {
    const $thisElement = $(e.currentTarget);
    let inputValue = $thisElement.val();

    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, -1);
    }

    $thisElement.val(_self._leadingZero(parseInt(inputValue)));
  });

  _self.$inputNumber.on('click', (e) => {
    const $thisElement = $(e.currentTarget);

    $thisElement.val('');

  });

  $(window).on('click', (e) => {
    _self.$inputNumber.each((index, item) => {
      const $thisInput = $(item);
      const thisValue = $thisInput.val();
      if (thisValue === '' && !$(e.target).closest($thisInput).length) {
        if  (index === 0) {
          $(_self.$inputNumber[index]).val(defaultAdultsNumber);
        } else {
          $(_self.$inputNumber[index]).val(defaultChildrenNumber);
        }
      }
    });
  });

  _self.$inputNumber.on('keydown', (e) => {
    const $thisElement = $(e.currentTarget);
    const key = e.key;

    if (key === 'Backspace') {
      $thisElement.val('');
    }

  });

  function _leadingZero(number) {
    let newNumber = number.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });

    return newNumber;
  }

  if(_self.$btnBooking.length){
    _self.$btnBooking.attr("disabled","true");
  }
  $( document ).ready(function() {
    _self.$btnBooking.removeAttr("disabled");
  });
  _self.$formValidation.on('submit', (event) => {
    if (_self.$formValidation.valid()) {
      let newBookingUrl;
      event.preventDefault();
      // change BOOKING_URL
      _self._handleGetValue();
      const idCustomer = window.ga.getAll()[0].get('clientId');
      newBookingUrl = bookingUrl + BOOKING_URL + '&viewrates=rooms' + '&clientid=' + idCustomer;

      let sessionId = '';
      let gtagSettingId = _self.$el.data('gtag-id');
      if ( gtagSettingId ) {
        gtag('get', gtagSettingId, 'session_id', ( id ) => {
          sessionId = id;
        });
      }

      //trigger event gtm
      _self.$btnBooking.trigger('book_now_homepage', DATA_GTM);
      // window.location.href = newBookingUrl;
      setTimeout(() => {
        newBookingUrl += `&sessionid=${sessionId}`;
        window.open(newBookingUrl, '_blank');
      }, 100);
    }

  });

  function _handleGetValue() {

    const $allField = _self.$formValidation.find('[name]');
    BOOKING_URL =  '';
    DATA_GTM = {};
    $allField.each((index, element) => {
      const $thisElement = $(element);
      const nameAttr = $thisElement.attr('name');

      if (nameAttr === 'hotelcode') {
        const hotelCode = $thisElement.data('hotel-code');
        let thisElementHash = `?${nameAttr}=${hotelCode}`;
        BOOKING_URL += thisElementHash;
        DATA_GTM.hotelName = $thisElement.val();
      } else if (nameAttr === 'daterange') {
        let thisElementValue = $thisElement.val();
        thisElementValue = thisElementValue.split('-');

        thisElementValue = thisElementValue.map((item)=> item.trim());

        thisElementValue = thisElementValue.map((item) => {
          let day = moment(item, 'DD MMM YYYY').format('YYYY-MM-DD');
          return day;
        });

        let thisElementHash = `&checkin=${thisElementValue[0]}&checkout=${thisElementValue[1]}`;
        BOOKING_URL += thisElementHash;
        DATA_GTM.checkin = thisElementValue[0];
        DATA_GTM.checkout = thisElementValue[1];
      } else if (nameAttr === 'adults') {
        let numberValue = $thisElement.val();
        numberValue = parseInt(numberValue, 10);
        let thisElementHash = `&${nameAttr}=${numberValue}`;
        BOOKING_URL += thisElementHash;
        DATA_GTM.adults = numberValue;
      } else if (nameAttr === 'children') {
        let numberValue = $thisElement.val();
        numberValue = parseInt(numberValue, 10);
        let thisElementHash = `&${nameAttr}=${numberValue}`;
        BOOKING_URL += thisElementHash;
        DATA_GTM.children = numberValue;
      } else if (nameAttr === 'promocode') {
        let promoValue = $thisElement.val();
        let thisElementHash = `&${nameAttr}=${promoValue}`;
        BOOKING_URL += thisElementHash;
      }
    });
  }

  function _handleInOutViewPort() {
    const bookingTop = _self.$bookingContent.offset().top;
    const bookingBottom = bookingTop + _self.$bookingContent.outerHeight(true);

    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();

    if (bookingBottom > viewportTop && bookingTop < viewportBottom) {
      _self.$fixedButton.css({
        opacity: '',
      });
    } else {
      if (bookingBottom < viewportTop) {
        _self.$fixedButton.css({
          opacity: '1',
        });
      }
    }
  }

  _self._handleInOutViewPort();

  $(window).on('scroll', () => {
    _self._handleInOutViewPort();
  });

  $(window).on('resize', () => {
    _self._spacingBottomBooking();
    _self._handleInOutViewPort();
  });

  _self.$fixedButton.on('click', () => {
    const bookingTop = _self.$bookingContent.offset().top;
    const bookingHeight = _self.$bookingContent.outerHeight(true);
    const windowHeight = $(window).height();
    const remainHeight = windowHeight - bookingHeight;
    const centerPosition = bookingTop - (remainHeight/2);

    $('html, body').animate({
      scrollTop: centerPosition
    }, 1500);
  });

  function _spacingBottomBooking() {
    if (_self.$el.css('position') === 'relative') {
      const height = _self.$el.find('.booking__content').outerHeight(true);
      _self.$el.css('margin-bottom', height/2 + 60);
    }
  }

  _self._spacingBottomBooking();

}

BookingController.prototype = Object.create(JpControllerBase.prototype);

BookingController.prototype.render = function () {
  const _self = this;

};

export default BookingController;
